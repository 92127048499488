<template>
  <div class="my-second mycode">
      <Head :title="$t('mine.add_re_code')" :show="true" :bg="true"/>
      <div class="add-code-box">
            <img class="my-banner" :src="banner" alt="">
            <div class="mycode-main" v-if="user">
                <div class="mycode-top">
                    <img :src="user.headimgurl" alt="">
                    <div class="my-info">
                        <p>{{$t('mine.iam')}}:{{user.username}}</p>
                        <p>{{$t('mine.welcome')}} </p>
                    </div>
                </div>
                <div class="code-info">
                    <p class="qrcode">{{$t('mine.add_re_code')}}</p>
                    <p class="qrcode-num">{{user.recommend_code}}</p>
                    <p class="qrcode-copy" @click="onCopy">{{$t('mine.copy')+(lang=='en'?' ':'')+$t('mine.lianjie')}}</p>
                </div>
                <div class="dotted-line"></div>
                <div class="qrcode-img">
                    <div id="qrcode" ></div>
                    <!-- <img src="../../assets/images/qrcode.png" alt=""> -->
                </div>
                <div class="qrcode-last">
                    <p class="qrcode-last-one">{{$t('mine.scan_code')}}</p>
                    <!-- <p class="qrcode-last-two">{{$t('mine.long_press')}}</p> -->
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import {myinfo} from "@/common"
import {Toast} from "vant"
export default {
    name:"mycode",
    mixins:[myinfo],
    data(){
        return {
            lang:localStorage.getItem("langData") || 'th',
            banner:'',
            mydash:JSON.parse(localStorage.getItem('dashboard'))
        }
    },
    mounted(){
         this.$ajax.announcement()
        .then(res=>{
            if(res.code==200){
                this.banner  = res.data.rbanner[0]
            }
        })

        var qrcode = new QRCode('qrcode', {
                width: 240,
                height: 240,
                colorDark : '#000000',
                colorLight : '#ffffff',
                correctLevel : QRCode.CorrectLevel.H,
                // text:link
        });

        var recommend_code = this.user.recommend_code||this.mydash.recommend_code;
        var link = this.$host+ "#/register?recommend_code="+recommend_code;
        qrcode.makeCode(link)
    },
    methods:{
        onCopy() {
            // /register?_t=1611124290793
            var share_text = this.$t('add.share_py') + ' \n \n  ' 
            var link = this.$host+ "#/register?recommend_code="+this.user.recommend_code;
            var flag = this.copyText(share_text+link);
            Toast.success(this.$t('news.copysuccess'))
        },
        copyText(shareLink) {
          var _input = document.createElement("input");   // 直接构建input
          _input.value = shareLink;  // 设置内容
          document.body.appendChild(_input);    // 添加临时实例
          _input.select();   // 选择实例内容
          document.execCommand("Copy");   // 执行复制
          document.body.removeChild(_input); // 删除临时实例
      },
    }
}
</script>
